<template>
  <div/>
</template>

<script>
import BaseAction from '@/components/actions/BaseAction';
import JetCollection from "@/components/JetCollection";

export default {
  mixins: [
    BaseAction,
  ],
  name: 'GoToRoute',
  mounted() {
    if (!this.selected) {
      return;
    }
    this.open();
  },
  methods: {
    async open() {
      // 2 Пассажирские перевозки
      await jet.collections.open({
        id: JetCollection.constants.SCHEDULE_GUID,
        uri: "scheduleEditTool:/?",
        name: "Расписание",
      });
      const routeId = this.selected.vcroutesId;
      if (routeId) {
        setTimeout(()=>{
          jet.collections
            .active
            .table()
            .set("route", routeId);
        }, 500);
      } else {
        this.showMessage('warning', 'Расписание для данного маршрута не найдено');
      }
    },
  },
}
</script>
